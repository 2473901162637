<template>
  <div id="main">
    <div class="help-index">
      <div class="help-title">
        <div class="wrap clearfix">
          <h2>帮助中心</h2>
          <div class="hot-box">
            <h5>热门问题：</h5>
            <ul>
              <li v-for="(v,i) in host" :key="i" class="">
                <a :href="v.href"
                  ><span class="imv2-dot_samll"></span
                  >{{v.name}}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="help-classify">
        <div class="wrap clearfix">
          <div v-for="(v,i) in datas" :key="i" class="class-box">
            <div
              class="left-img"
              style="
                background-image: url(//img3.sycdn.imooc.com/5b7e48e100012c6601140190.jpg);
              "
            ></div>
            <div class="right-content">
              <h2><a :href="`#/helps/${v.name}`">{{v.name}}</a></h2>
              <ul>
                <li v-for="(d,i) in v.children" :class="{'last':i%2==0}" :key="'i'+i" class=""><a :href="`#/helps/${v.name}/${d.name}`">{{d.name}}</a></li>
              </ul>
              <a href="#/help/cate/2" class="more"
                >更多<span class="imv2-arrow2_r"></span
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="help-all">
        <a href="#/help/default"
          >显示全部<span class="imv2-arrow2_r"></span
          ><span class="imv2-arrow2_r"></span
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../ulti/ajax";
export default {
data(){
  return {
    datas:[],
    host:[]
  }
},
 async created(){
   this.datas = (await api.getHelptypeList(1)).data.rows;
   this.host = (await api.getHelpListHots()).data.rows
    let arr = {};
    for(let pop of this.datas){
      if(pop.parentid==0){
        arr[pop.id]={
          name:pop.name,
          parentid:pop.parentid,
          children:[]
        }
      }else{
        if(arr[pop.parentid]){
          arr[pop.parentid].children.push(pop)
        }else{
          arr[pop.parentid] ={}
           arr[pop.parentid].children =[]
          arr[pop.parentid].children.push(pop)
        }
      }
    }
    let p = []
    for(let pop in arr){
      p.push(arr[pop])
    }
    this.datas = p
    console.log(this.$router)
  }
};
</script>

<style>
@import url('../../assets/css/help.css');
</style>
